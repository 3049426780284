export const EMPLOYMENT_TYPE_OPTIONS = [
  { value: '1', label: 'Per diem' },
  { value: '2', label: 'Full Time' },
  { value: '3', label: 'Part Time' },
  { value: '4', label: 'Travel contract' },
];

export const PATIENT_CARE_OPTIONS = [
  { value: true, label: 'Yes' },
  { value: false, label: 'No' },
];
