import { Head } from '@medely/web-components';
import { useDeviceInfo } from '@medely/web-components/hooks';
import React, { Suspense, lazy, useEffect } from 'react';
import { Redirect, Route, Switch, useHistory, useLocation } from 'react-router-dom';
import { useAnalytics, useConfigurationContext } from '@medely/ui-kit';
import useCurrentUser from 'hooks/useCurrentUser';
import useHandleError from 'hooks/useHandleError';
import moment from 'moment';
import useOnboarding from 'hooks/useOnboarding';
import { useQueryClient } from '@tanstack/react-query';
import LocationRedirect from 'components/LocationRedirect';
import { createRedirectSearch } from 'utils/createRedirectSearch';
import { sessionTools } from '@medely/base';
import { isSaasCredentialingAccount } from 'utils/account';
import ProView from 'View';
import { GooglePlacesLoaderProvider } from '@medely/pro-multi-platform';
import { BooleanParam, StringParam, useQueryParam, useQueryParams } from 'use-query-params';
import { WebViews } from 'components/WebViews/WebViews';
import CenteredLoader from 'components/CenteredLoader';
import MobileAuthLogin from 'components/auth/MobileAuthLogin';
import { pageTitles } from 'constants/pageTitles';
import { useInviteRedirect } from 'components/auth/AcceptInvite/useInviteRedirect';
import configKeys from 'config';

// a function to retry loading a chunk to avoid chunk load error for out of date code
const lazyRetry = function (componentImport, name) {
  return new Promise((resolve, reject) => {
    // check if the window has already been refreshed
    const hasRefreshed = JSON.parse(
      window.sessionStorage.getItem(`retry-${name}-refreshed`) || 'false',
    );
    // try to import the component
    componentImport()
      .then((component) => {
        window.sessionStorage.setItem(`retry-${name}-refreshed`, 'false'); // success so reset the refresh
        resolve(component);
      })
      // if import fails, refresh the page automatically
      .catch((error) => {
        if (!hasRefreshed) {
          // not been refreshed yet
          window.sessionStorage.setItem(`retry-${name}-refreshed`, 'true'); // we are now going to refresh
          return window.location.reload(); // refresh the page
        }
        reject(error); // Default error behaviour as already tried refresh
      });
  });
};

// @ts-ignore PRO-3490
const Login = lazy(() => lazyRetry(() => import('components/auth/Login'), 'login'));
// @ts-ignore PRO-3490
const SignUp = lazy(() => lazyRetry(() => import('components/auth/SignUp'), 'sign-up'));
// @ts-ignore PRO-3490
const AcceptInvite = lazy(() => lazyRetry(() => import('components/auth/AcceptInvite'), 'invite'));
// @ts-ignore PRO-3490
const Create = lazy(() => lazyRetry(() => import('components/auth/Create'), 'create'));
const SetPassword = lazy(() =>
  // @ts-ignore PRO-3490
  lazyRetry(() => import('components/auth/SetPassword'), 'set-password'),
);
const ResetPassword = lazy(() =>
  // @ts-ignore PRO-3490
  lazyRetry(() => import('components/auth/ResetPassword'), 'reset-password'),
);
const ReferenceGateway = lazy(() =>
  // @ts-ignore PRO-3490
  lazyRetry(() => import('routes/reference-gateway/Root'), 'reference'),
);
const ApplicationRoot = lazy(() =>
  // @ts-ignore PRO-3490
  lazyRetry(() => import('routes/application/Root'), 'application'),
);
const AssignmentsRoot = lazy(() =>
  // @ts-ignore PRO-3490
  lazyRetry(() => import('routes/assignments/Root'), 'assignments'),
);
// @ts-ignore PRO-3490
const AvailableRoot = lazy(() => lazyRetry(() => import('routes/available/Root'), 'available'));
const CredentialsRoot = lazy(() =>
  // @ts-ignore PRO-3490
  lazyRetry(() => import('routes/credentials/Root'), 'credetials'),
);
const MyAssignmentsRoot = lazy(() =>
  // @ts-ignore PRO-3490
  lazyRetry(() => import('routes/my-assignments/MyAssignmentsRoot'), 'my-assignments'),
);
// @ts-ignore PRO-3490
const EarningsRoot = lazy(() => lazyRetry(() => import('routes/earnings/Root'), 'earnings'));
// @ts-ignore PRO-3490
const JobsRoot = lazy(() => lazyRetry(() => import('routes/jobs/Root'), 'jobs'));
// @ts-ignore PRO-3490
const ProfileRoot = lazy(() => lazyRetry(() => import('routes/profile/Root'), 'profile'));
// @ts-ignore PRO-3490
const ScheduleRoot = lazy(() => lazyRetry(() => import('routes/schedule/Root'), 'schedule'));
// @ts-ignore PRO-3490
const TimecardsRoot = lazy(() => lazyRetry(() => import('routes/timecards/Root'), 'timecards'));
// @ts-ignore PRO-3490
const TodayRoot = lazy(() => lazyRetry(() => import('routes/today/Root'), 'today'));
// @ts-ignore PRO-3490
const SettingsRoot = lazy(() => lazyRetry(() => import('routes/settings/Root'), 'settings'));
// @ts-ignore PRO-3490
const OnboardingRoot = lazy(() => lazyRetry(() => import('routes/onboarding/Root'), 'onboarding'));

const AppRouter = () => {
  const config = useConfigurationContext();
  const { isLoading: loadingUser, currentUser, isApplicant } = useCurrentUser();
  const {
    showOnboarding,
    hasAnOnboardedPosition,
    isAssignmentOnboardingRequired,
    assignmentsMissingConditions,
  } = useOnboarding();

  const url = new URL(window.location.href);
  const search = createRedirectSearch(url);

  const [{ webView, mobileToken }] = useQueryParams({
    mobileToken: StringParam,
    webView: BooleanParam,
  });

  const urlHasMobileAuthToken = !!mobileToken;

  const onError = useHandleError();
  const queryClient = useQueryClient();
  queryClient.setDefaultOptions({
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      // queries going stale was messing up our multi-page forms
      // we can change this to something more aggressive, but we need
      // to be very careful about queries that populate form options
      staleTime: Infinity,
      onError,
    },
    mutations: {
      onMutate: (context) => context,
      onSettled: () => {
        queryClient.invalidateQueries();
      },
      onError,
    },
  });

  const analytics = useAnalytics();
  const deviceInfo = useDeviceInfo();
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    sessionTools.recordActivity(() =>
      analytics.track({ context: 'Session', action: 'Started', session_access_type: 'web' }),
    );
  }, [location.pathname, location.search]);

  useEffect(() => {
    analytics.page('Professionals', location.pathname);
  }, [location.pathname]);

  const followingQualificationRedirect = !!useQueryParam('credential', StringParam)[0];

  const initialRedirectForOnboarding = () => {
    if (
      currentUser &&
      currentUser.professional?.status === 'active' &&
      !webView &&
      !mobileToken &&
      !followingQualificationRedirect
    ) {
      if (showOnboarding && !hasAnOnboardedPosition && !location.pathname.includes('/onboarding')) {
        history.push('/onboarding');
      } else if (
        !showOnboarding &&
        isSaasCredentialingAccount(currentUser) &&
        !location.pathname.includes('/credentials')
      ) {
        history.push('/credentials');
      } else if (isAssignmentOnboardingRequired && !location.pathname.includes('/onboarding')) {
        history.push(`/assignments/${assignmentsMissingConditions[0]}/onboarding`);
      }
    }
  };
  useEffect(initialRedirectForOnboarding, [
    currentUser?.id,
    showOnboarding,
    assignmentsMissingConditions.length,
  ]);
  useInviteRedirect();

  const identifyUser = () => {
    if (currentUser) {
      const professionalType = (() => {
        if (currentUser.company_id) {
          return 'irp';
        }
        if (currentUser.agency_id) {
          return 'agency';
        }
        return 'marketplace';
      })();

      analytics.identify(
        currentUser.id.toString(),
        {
          name: currentUser.name,
          email: currentUser.email,
          hide_default_launcher: true,
          status: currentUser.professional?.status,
          application_status: currentUser.professional?.application_status,
          professional: true,
          companyID: currentUser.company_id,
          agencyID: currentUser.agency_id,
          professionalType,
        },
        {
          Intercom: { user_hash: currentUser.user_hash },
        },
      );

      analytics.track('Professional Login', {
        Date: moment().format('MM/DD/YYYY'),
        OperatingSystem: deviceInfo.current.operatingSystem,
        OperatingSystemVersion: deviceInfo.current.operatingSystemVersion,
        BrowserVersion: deviceInfo.current.browserVersion,
        Device: deviceInfo.current.device,
      });
    }
  };
  useEffect(identifyUser, [currentUser?.id]);

  if (webView) {
    return <WebViews />;
  }

  if (!!urlHasMobileAuthToken) {
    return <MobileAuthLogin />;
  }

  if (loadingUser) {
    return <CenteredLoader />;
  }

  if (!currentUser) {
    return (
      <Suspense fallback={<CenteredLoader />}>
        <Head>
          {/* default so the previous page title isn't displayed when the current page title is undefined */}
          <title>{pageTitles.default}</title>
        </Head>
        <Switch>
          <Route path={`${config.root}/login`} component={Login} />
          <Route path={`${config.root}/invite`} component={AcceptInvite} />
          <Route path={`${config.root}/sign-up`} component={SignUp} />
          <Route path={`${config.root}/create`} component={Create} />
          <Route path={`${config.root}/set-password`} component={SetPassword} />
          <Route path={`${config.root}/reset-password`} component={ResetPassword} />
          <Route path={`${config.root}/reference-gateway`} component={ReferenceGateway} />
          <Route
            path={`${config.root}/assignments_boards/:id`}
            component={(props) => <LocationRedirect {...props} host={config.landingsUrl} />}
          />
          <Redirect to={{ pathname: `${config.root}/login`, search: search }} />
        </Switch>
      </Suspense>
    );
  } else {
    return (
      <GooglePlacesLoaderProvider apiKey={configKeys.googleMapsKey}>
        <ProView>
          <Suspense fallback={<CenteredLoader />}>
            <Head>
              {/* default so the previous page title isn't displayed when the current page title is undefined */}
              <title>{pageTitles.default}</title>
            </Head>
            <Switch>
              <Route path={`${config.root}/application`} component={ApplicationRoot} />
              <Route path={`${config.root}/assignments`} component={AssignmentsRoot} />
              <Route path={`${config.root}/available`} component={AvailableRoot} />
              <Route path={`${config.root}/credentials`} component={CredentialsRoot} />
              <Route path={`${config.root}/my-assignments`} component={MyAssignmentsRoot} />
              <Route path={`${config.root}/earnings`} component={EarningsRoot} />
              <Redirect
                from={`${config.root}/jobs/assignments`}
                to={{
                  pathname: `${config.root}/available/assignments/local`,
                  search: url.search,
                }}
              />
              <Redirect
                from={`${config.root}/available/local`}
                to={{
                  pathname: `${config.root}/available/jobs`,
                  search: url.search,
                }}
              />
              <Redirect
                from={`${config.root}/jobs/available`}
                to={{
                  pathname: `${config.root}/available/jobs`,
                  search: url.search,
                }}
              />
              <Route path={`${config.root}/jobs`} component={JobsRoot} />
              <Route path={`${config.root}/profile`} component={ProfileRoot} />
              <Route path={`${config.root}/schedule`} component={ScheduleRoot} />
              <Route path={`${config.root}/timesheets`} component={TimecardsRoot} />
              <Route path={`${config.root}/settings`} component={SettingsRoot} />
              <Route path={`${config.root}/onboarding`} component={OnboardingRoot} />
              <Route
                path={`${config.root}/assignments_boards/:id`}
                component={(props) => <LocationRedirect {...props} host={config.landingsUrl} />}
              />
              <Redirect
                from={`${config.root}/dashboard/credentials`}
                to={!isApplicant ? `${config.root}/credentials` : `${config.root}/application`}
              />
              <Redirect from={`${config.root}/dashboard/*`} to={`/`} />
              <Redirect
                from={`${config.root}/dashboard`}
                to={
                  !isApplicant
                    ? `${config.root}/my-assignments/applications`
                    : `${config.root}/application`
                }
              />
              <Redirect
                from={`${config.root}/jobs-dashboard`}
                to={`${config.root}/my-assignments/applications`}
              />
              {!isApplicant && isSaasCredentialingAccount(currentUser) && (
                <Redirect from={config.root} to={`${config.root}/credentials`} />
              )}
              <Route path={`${config.root}`} component={TodayRoot} />
              <Redirect to={`${config.root}`} />
            </Switch>
          </Suspense>
        </ProView>
      </GooglePlacesLoaderProvider>
    );
  }
};

export default AppRouter;
