import { MedelyDateTime } from '@medely/date-time-tools';
import { FragmentType, graphql, parseFragmentData } from '../../../../graphql/generated';
import { ValuesProps, WorkHistoryProps } from './types';

const UseWorkExperienceWorkHistoryGql = graphql(`
  fragment UseWorkExperienceWorkHistory on WorkHistory {
    id
    currently_work_here
    employment_type
    end_date
    location_type_id
    start_date
    position {
      id
    }
    wage_logs {
      hourly_rate_cents
    }
  }
`);

const UseWorkExperienceCurrentUserGql = graphql(`
  fragment UseWorkExperienceCurrentUser on Account {
    professional {
      professional_positions {
        years_of_experience
        position {
          id
          display_name
        }
      }
    }
  }
`);

type UseWorkExperienceProps = {
  currentUser?: FragmentType<typeof UseWorkExperienceCurrentUserGql>;
  workHistories?: FragmentType<typeof UseWorkExperienceWorkHistoryGql>[] | [];
};

type GetMissingMonthsProps = {
  startDate: string;
  endDate?: string;
};

type IsMissingYoEProps = {
  startDate: string;
  endDate?: string;
  positionId: number | undefined;
};

type isMissingYoEFromMultiplesWorkHistoriesProps = {
  positionId?: number;
};

export const useWorkExperience = ({
  currentUser: inputCurrentUser,
  workHistories: inputWorkHistories,
}: UseWorkExperienceProps) => {
  const currentUser = parseFragmentData(UseWorkExperienceCurrentUserGql, inputCurrentUser);
  const workHistories = parseFragmentData(UseWorkExperienceWorkHistoryGql, inputWorkHistories);

  const calculateTotalYearsOfExperience = (): number => {
    let totalYearsOfExperience = 0;
    currentUser?.professional?.professional_positions?.forEach(
      (p) => (totalYearsOfExperience += p.years_of_experience || 0),
    );
    return totalYearsOfExperience;
  };

  const totalYearsOfExperience = calculateTotalYearsOfExperience();

  const getPositionsOptions = () => {
    const options: { value: number; label: string }[] = [];
    currentUser?.professional?.professional_positions?.map((position) => {
      options.push({
        value: position.position.id,
        label: position.position.display_name ?? '',
      });
    });
    return options;
  };

  const positionsOptions = getPositionsOptions();

  const getInitialWorkExperienceValues = (workHistoryData: WorkHistoryProps) => {
    return {
      position_id: workHistoryData?.position?.id,
      bedside_care: workHistoryData?.bedside_care,
      currently_work_here: workHistoryData?.currently_work_here,
      end_date: workHistoryData?.end_date,
      employment_type: workHistoryData?.employment_type,
      location_type_id: workHistoryData?.location_type_id,
      hourly_rate_cents: workHistoryData?.wage_logs?.hourly_rate_cents,
      start_date: workHistoryData?.start_date,
    };
  };

  const getAddedMonths = ({ startDate, endDate }: GetMissingMonthsProps) => {
    const onMonth = 365 / 12;
    const parsedStartDate = new MedelyDateTime(new Date(startDate).toISOString(), {
      tz: 'system',
    });
    const parsedEndDate = endDate
      ? new MedelyDateTime(new Date(endDate).toISOString(), {
          tz: 'system',
        })
      : new MedelyDateTime(new Date().toISOString(), {
          tz: 'system',
        });

    const missingDays = parsedStartDate.difference(parsedEndDate, 'days');

    const missingMonths = Math.round(missingDays / onMonth);
    return missingMonths;
  };

  const getAddedMonthsFromMultiplesWorkHistories = (positionId?: number) => {
    const selectedPositionWorkHistories = !!workHistories
      ? workHistories.filter((w) => w?.position?.id === positionId)
      : undefined;

    let addedMonths = 0;
    if (selectedPositionWorkHistories) {
      selectedPositionWorkHistories.map((selectedPositionWorkHistory) => {
        addedMonths += getAddedMonths({
          startDate: selectedPositionWorkHistory?.start_date,
          endDate: selectedPositionWorkHistory?.end_date,
        });
      });
      return addedMonths;
    } else {
      return 0;
    }
  };

  const createPayload = (values: ValuesProps) => {
    const wageLogData = !!values.hourly_rate_cents
      ? {
          wage_log: {
            hourly_rate_cents: values.hourly_rate_cents,
            position_id: values.position_id,
          },
        }
      : null;
    return {
      source: 'application_wizard',
      work_history: {
        clinical: true,
        position_id: values.position_id,
        location_type_id: values.location_type_id,
        bedside_care: values.bedside_care,
        employment_type: values.employment_type,
        currently_work_here: values.currently_work_here,
        start_date: values.start_date,
        end_date: values.end_date,
        ...wageLogData,
      },
    };
  };

  const createEditPayload = (values: ValuesProps) => {
    const wageLogData = !!values.hourly_rate_cents && {
      wage_log: {
        hourly_rate_cents: values.hourly_rate_cents,
        position_id: values.position_id,
      },
    };
    return {
      id: values.id,
      position_id: values.position_id,
      location_type_id: values.location_type_id,
      bedside_care: values.bedside_care,
      employment_type: values.employment_type,
      currently_work_here: values.currently_work_here,
      start_date: values.start_date,
      end_date: values.end_date,
      ...wageLogData,
    };
  };

  const isMissingYoE = ({ startDate, endDate, positionId }: IsMissingYoEProps) => {
    const initialAddedMonths = getAddedMonths({
      startDate: startDate,
      endDate: endDate,
    });

    const crnPositionId = 25;
    if (crnPositionId === positionId) {
      return initialAddedMonths < 6 ? true : false;
    }
    return initialAddedMonths < 8 ? true : false;
  };

  const isMissingYoEFromMultiplesWorkHistories = ({
    positionId,
  }: isMissingYoEFromMultiplesWorkHistoriesProps) => {
    const addedMonths = getAddedMonthsFromMultiplesWorkHistories(positionId);

    const crnPositionId = 25;
    if (crnPositionId === positionId) {
      return addedMonths < 6 ? true : false;
    }
    return addedMonths < 8 ? true : false;
  };

  return {
    totalYearsOfExperience,
    positionsOptions,
    getInitialWorkExperienceValues,
    getAddedMonths,
    createPayload,
    isMissingYoE,
    getAddedMonthsFromMultiplesWorkHistories,
    isMissingYoEFromMultiplesWorkHistories,
    createEditPayload,
  };
};
