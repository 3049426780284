import React, { ReactElement } from 'react';
import { IJob } from '@medely/types';
import { Box, Text } from '@medely/ui-kit';
import { useDistanceMatrix } from '../../hooks';
import { Link } from '../Link';

export interface IShiftLocationProps {
  bookVariant: 'pre-book' | 'post-book';
  distanceVariant: 'local' | 'travel';
  job: IJob;
  userCoordinates: { latitude?: number; longitude?: number };
}

export const ShiftLocation = ({
  bookVariant,
  distanceVariant,
  job,
  userCoordinates,
}: IShiftLocationProps): ReactElement => {
  const { distance } = useDistanceMatrix({
    job,
    userLat: userCoordinates?.latitude ?? 0,
    userLong: userCoordinates?.longitude ?? 0,
    showMileageInJobDetails: true,
  });
  const {
    location: { address_city, address_zip, full_address, location_type, name, state },
  } = job;
  const facilityType = `Facility Type: ${location_type?.label}`;

  const Address = () =>
    bookVariant === 'pre-book' ? (
      <Text size="m">{`${address_city}, ${state?.abbreviation} ${address_zip}`}</Text>
    ) : (
      <>
        <Text size="m">{name}</Text>
        <Link
          url={`https://google.com/maps/search/?api=1&query=${encodeURIComponent(
            full_address ?? '',
          )}`}
          variant="text"
          size="m"
        >
          {full_address}
        </Link>
      </>
    );

  return (
    <Box testId="shift-location">
      <Address />
      <Text size="m">{facilityType}</Text>
      {distanceVariant === 'local' ? <Text size="m">{distance ?? ''}</Text> : <></>}
    </Box>
  );
};
